import { ApolloClient, ApolloLink, concat, HttpLink, InMemoryCache } from '@apollo/client'
import { ChainId } from '@uniswap/sdk-core'
import { DEFAULT_CHAIN_ID } from 'constants/misc'

import store from '../../state/index'

const BTTC_GRAPH_URL = `${process.env.REACT_APP_V3_GRAPH_URL_BTTC}`
const FTM_GRAPH_URL = `${process.env.REACT_APP_V3_GRAPH_URL_FTM}`
const EON_GRAPH_URL = `${process.env.REACT_APP_V3_GRAPH_URL_EON}`
const BERA_TESTNET_GRAPH_URL = `${process.env.REACT_APP_V3_GRAPH_URL_BERA_TESTNET}`
const CHAIN_SUBGRAPH_URL: Record<number, string> = {
  [ChainId.MAINNET]: 'https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v3?source=uniswap',
  [ChainId.ARBITRUM_ONE]: 'https://thegraph.com/hosted-service/subgraph/ianlapham/uniswap-arbitrum-one?source=uniswap',
  [ChainId.OPTIMISM]: 'https://api.thegraph.com/subgraphs/name/ianlapham/optimism-post-regenesis?source=uniswap',
  [ChainId.POLYGON]: 'https://api.thegraph.com/subgraphs/name/ianlapham/uniswap-v3-polygon?source=uniswap',
  [ChainId.CELO]: 'https://api.thegraph.com/subgraphs/name/jesse-sawa/uniswap-celo?source=uniswap',
  [ChainId.BNB]: 'https://api.thegraph.com/subgraphs/name/ianlapham/uniswap-v3-bsc?source=uniswap',
  [ChainId.AVALANCHE]: 'https://api.thegraph.com/subgraphs/name/lynnshaoyu/uniswap-v3-avax?source=uniswap',
  [ChainId.BIT_TORRENT_MAINNET]: BTTC_GRAPH_URL,
  [ChainId.FANTOM]: FTM_GRAPH_URL,
  [ChainId.EON]: EON_GRAPH_URL,
  [ChainId.BERA_TESTNET]: BERA_TESTNET_GRAPH_URL,
}

const httpLink = new HttpLink({ uri: CHAIN_SUBGRAPH_URL[DEFAULT_CHAIN_ID] })

// This middleware will allow us to dynamically update the uri for the requests based off chainId
// For more information: https://www.apollographql.com/docs/react/networking/advanced-http-networking/
const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  const chainId = store.getState().application.chainId

  operation.setContext(() => ({
    uri: chainId && CHAIN_SUBGRAPH_URL[chainId] ? CHAIN_SUBGRAPH_URL[chainId] : CHAIN_SUBGRAPH_URL[DEFAULT_CHAIN_ID],
  }))

  return forward(operation)
})

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authMiddleware, httpLink),
})
