import { SwapSide } from '@paraswap/sdk'
import { Currency, CurrencyAmount, Percent, Price, TradeType } from '@uniswap/sdk-core'
import { ONE_HUNDRED_PERCENT, PARASWAP_PARTNER_ID } from 'constants/misc'
import { getBestTradeCurrencyAddress, useParaswap } from 'hooks/useParaswap'
import React from 'react'
import { useQuery } from 'react-query'
import { IHasQuoteProperties, QuoteMethod, TradeFillType } from 'state/routing/types'

import { IQuoteSwapParams, Quoters } from './QuoteManager'

interface QuoterProps {
  swapParams: IQuoteSwapParams
  onQuoteUpdate: (quoter: Quoters, quote: IHasQuoteProperties | null) => void
}

// Helper function to add zeros to the end of a string
function addZerosToEnd(str: string, numOfZeros: number): string {
  return str + '0'.repeat(numOfZeros)
}

// Helper function to normalize prices for percentage calculation
function normalizePricesForPercent(srcValue: string, dstValue: string) {
  const srcDecimals = srcValue.split('.')[1]?.length || 0
  const destDecimals = dstValue.split('.')[1]?.length || 0
  const maxDecimalPlaces = Math.max(srcDecimals, destDecimals)

  if (maxDecimalPlaces === srcDecimals) {
    return {
      normalizedSrcUSD: srcValue.replace('.', ''),
      normalizedDestUSD: addZerosToEnd(dstValue.replace('.', ''), srcDecimals - destDecimals),
    }
  }

  return {
    normalizedSrcUSD: addZerosToEnd(srcValue.replace('.', ''), destDecimals - srcDecimals),
    normalizedDestUSD: dstValue.replace('.', ''),
  }
}

function calculateParaswapPriceImpact(optimalRateData: any | undefined): Percent | undefined {
  if (!optimalRateData || !optimalRateData.srcUSD || !optimalRateData.destUSD) return undefined

  const prices = normalizePricesForPercent(optimalRateData.srcUSD, optimalRateData.destUSD)
  return ONE_HUNDRED_PERCENT.subtract(new Percent(prices.normalizedDestUSD, prices.normalizedSrcUSD)) // Compute price impact from normalized USD values
}

// eslint-disable-next-line import/no-unused-modules
export const ParaswapQuoter: React.FC<QuoterProps> = ({ swapParams, onQuoteUpdate }) => {
  const paraswap = useParaswap()

  // Consolidated function to fetch and resolve the quote
  const fetchAndResolveQuote = async () => {
    if (!paraswap) {
      return null
    }

    const srcToken = getBestTradeCurrencyAddress(swapParams.inputCurrency)
    const destToken = getBestTradeCurrencyAddress(swapParams.outputCurrency)
    const srcDecimals = swapParams.inputCurrency.decimals
    const destDecimals = swapParams.outputCurrency.decimals

    try {
      // Fetch the rate from Paraswap
      const rate = await paraswap.swap.getRate({
        srcToken,
        destToken,
        srcDecimals,
        destDecimals,
        amount: swapParams.amount.toString(),
        side: swapParams.tradeType === TradeType.EXACT_INPUT ? SwapSide.SELL : SwapSide.BUY,
        options: {
          includeDEXS: ['SpookySwap', 'SpookySwapV3'],
          maxImpact: 100,
          partner: PARASWAP_PARTNER_ID,
          ignoreBadUsdPrice: true,
          srcTokenTransferFee: swapParams.inputTax?.toSignificant()
            ? (Number(swapParams.inputTax.toSignificant()) * 100).toString()
            : undefined,
          destTokenTransferFee: swapParams.outputTax?.toSignificant()
            ? (Number(swapParams.outputTax.toSignificant()) * 100).toString()
            : undefined,
        },
      })

      // Resolve the optimal rate into a quote
      const resolvedOptimalQuote = resolveQuoteFromOptimalRate(
        rate,
        swapParams.inputCurrency,
        swapParams.outputCurrency
      )
      const isOptimalQuoteValid = resolvedOptimalQuote.outputAmount && resolvedOptimalQuote.outputAmount.greaterThan(0)

      // Prepare the final quote object
      const quote: IHasQuoteProperties = {
        ...resolvedOptimalQuote,
        isValid: isOptimalQuoteValid,
      }

      return quote
    } catch (err) {
      console.error('Failed to fetch Paraswap rate', err)
      return null
    }
  }

  // Resolving the fetched rate into the IHasQuoteProperties format
  const resolveQuoteFromOptimalRate = (
    rate: any,
    inputCurrency: Currency,
    outputCurrency: Currency
  ): IHasQuoteProperties => {
    const rateInputAmount = CurrencyAmount.fromRawAmount(inputCurrency, rate.srcAmount)
    const rateOutputAmount = CurrencyAmount.fromRawAmount(outputCurrency, rate.destAmount)

    return {
      inputAmount: rateInputAmount,
      outputAmount: rateOutputAmount,
      executionPrice: new Price(
        rateInputAmount.currency,
        rateOutputAmount.currency,
        rateInputAmount.quotient,
        rateOutputAmount.quotient
      ),
      quote: rate,
      quoteMethod: QuoteMethod.BEST_TRADE_API,
      fillType: TradeFillType.BestSwap,
      tradeType: rate.side === SwapSide.SELL ? TradeType.EXACT_INPUT : TradeType.EXACT_OUTPUT,
      postTaxOutputAmount: rateOutputAmount,
      approveInfo: { needsApprove: false }, // Mocked for now, update if needed
      gasUseEstimateUSD: Number(rate.gasCostUSD),
      priceImpact: calculateParaswapPriceImpact(rate), // Use rate.destUSD directly for price impact
      fiatValueInput: { data: rate.srcUSD, isLoading: false },
      fiatValueOutput: { data: rate.destUSD, isLoading: false },
      dependencies: { quoter: Quoters.PARASWAP },
    }
  }

  // useQuery hook to fetch and resolve quotes
  useQuery({
    queryKey: ['fetchOptimalRate', swapParams],
    queryFn: fetchAndResolveQuote, // Directly call fetchAndResolveQuote
    enabled: true,
    refetchInterval: 5000, // Auto-refetch every 5 seconds
    onSuccess: (quote) => {
      if (quote) {
        onQuoteUpdate(Quoters.PARASWAP, quote)
      }
    },
  })

  return null // No rendering necessary
}
