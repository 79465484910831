import { ChainId, Currency, Percent, TradeType } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import Column from 'components/Column'
import { RowBetween, RowFixed } from 'components/Row'
import JSBI from 'jsbi'
import React, { useCallback, useEffect, useState } from 'react'
import { ChevronDown, Layers, Loader, Server } from 'react-feather'
import { IHasQuoteProperties, QuoteMethod } from 'state/routing/types'
import styled, { keyframes, useTheme } from 'styled-components'
import { ThemedText } from 'theme'

import { ClientSideQuoter } from './ClientSideQuoter'
import { LiquidityHubQuoter } from './LiquidityHubQuoter'
import { ParaswapQuoter } from './ParaswapQuoter'

const StyledHeaderRow = styled(RowBetween)<{ disabled: boolean; open: boolean }>`
  padding: 0;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
`

const RotatingArrow = styled(ChevronDown)<{ open?: boolean }>`
  transform: ${({ open }) => (open ? 'rotate(180deg)' : 'none')};
  transition: transform 0.1s linear;
`

const StyledPolling = styled.div`
  display: flex;
  height: 16px;
  width: 16px;
  margin-right: 2px;
  margin-left: 2px;
  align-items: center;
  color: ${({ theme }) => theme.neutral1};
  transition: 250ms ease color;
`

const StyledPollingDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.surface3};
  transition: 250ms ease background-color;
`

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const Spinner = styled.div`
  animation: ${rotate360} 1s cubic-bezier(0.83, 0, 0.17, 1) infinite;
  width: 14px;
  height: 14px;
  border-left: 2px solid ${({ theme }) => theme.neutral1};
  border-radius: 50%;
`

const Wrapper = styled(Column)`
  border: 1px solid ${({ theme }) => theme.surface3};
  border-radius: 16px;
  padding: 12px 16px;
  margin: 20px;
  margin-bottom: 0px;
  margin-top: 20px;
`

const getQuoteMethodIcon = (quoteMethod: QuoteMethod) => {
  switch (quoteMethod) {
    case QuoteMethod.ROUTING_API:
    case QuoteMethod.CLIENT_SIDE:
    case QuoteMethod.CLIENT_SIDE_FALLBACK:
      return <Loader size={16} />
    case QuoteMethod.BEST_TRADE_API:
      return <Server size={16} />
    case QuoteMethod.LIQUIDITY_HUB:
      return <Layers size={16} />
    default:
      return null
  }
}

const getQuoteMethodName = (quoteMethod: QuoteMethod) => {
  switch (quoteMethod) {
    case QuoteMethod.ROUTING_API:
    case QuoteMethod.CLIENT_SIDE:
    case QuoteMethod.CLIENT_SIDE_FALLBACK:
      return 'SpookySwap Router'
    case QuoteMethod.BEST_TRADE_API:
      return 'Best Trade API'
    case QuoteMethod.LIQUIDITY_HUB:
      return 'Liquidity Hub'
    default:
      return 'Unknown Provider'
  }
}

export interface IQuoteSwapParams {
  allowedSlippage: Percent
  inputCurrency: Currency
  outputCurrency: Currency
  inputTax: Percent
  outputTax: Percent
  tradeType: TradeType
  amount: JSBI
}

export enum Quoters {
  CLIENT = 'CLIENT',
  PARASWAP = 'PARASWAP',
  LIQUIDITY_HUB = 'LIQUIDITY_HUB',
}

// eslint-disable-next-line import/no-unused-modules
export const QuoteManager: React.FC<{
  swapParams: IQuoteSwapParams
  onSelectQuote: (quoter: Quoters, quote: IHasQuoteProperties) => void
}> = ({ swapParams, onSelectQuote }) => {
  const quoteDelay = Number(process.env.REACT_APP_QUOTE_DELAY_MS) || 120000

  const { inputCurrency, outputCurrency, amount } = swapParams

  const theme = useTheme()
  // State to store quotes from different quoters
  const [quotes, setQuotes] = useState<{ [key in Quoters]?: IHasQuoteProperties }>({})
  const [selectedQuoter, setSelectedQuoter] = useState<Quoters | undefined>(undefined)
  const { chainId } = useWeb3React()
  const [bypassDelay, setBypassDelay] = useState(false)

  const isClientEnabled = useCallback(
    (swapParams: IQuoteSwapParams) => {
      if (chainId === ChainId.FANTOM) {
        return bypassDelay || true // Enabled after delay or immediately if bypassed
      }
      return true // Enabled on all other chains without delay
    },
    [chainId, bypassDelay]
  )

  // Function to determine if Paraswap quoter is enabled
  const isParaswapEnabled = useCallback(
    (swapParams: IQuoteSwapParams) => {
      return chainId === ChainId.FANTOM // Disabled on Fantom, enabled on other chains
    },
    [chainId]
  )

  // Function to determine if Liquidity Hub quoter is enabled
  const isLiquidityHubEnabled = useCallback(
    (swapParams: IQuoteSwapParams) => {
      return (
        chainId === ChainId.FANTOM &&
        swapParams.tradeType === TradeType.EXACT_INPUT &&
        swapParams.inputTax.equalTo(0) &&
        swapParams.outputTax.equalTo(0)
      )
    },
    [chainId]
  )

  // Single useEffect handling both bypass and delay
  useEffect(() => {
    ;(window as any).enableClientQuotes = () => {
      setBypassDelay(true)
      console.log('ClientSide quotes enabled immediately via console')
    }

    if (!bypassDelay) {
      const timer = setTimeout(() => {
        setBypassDelay(true)
        console.log('ClientSide quotes enabled after delay')
      }, quoteDelay)

      return () => clearTimeout(timer)
    }

    return
  }, [bypassDelay, quoteDelay])

  // Handle quote updates
  const handleQuoteUpdate = useCallback((quoter: Quoters, quote: IHasQuoteProperties | null) => {
    setQuotes((prevQuotes) => {
      const existingQuote = prevQuotes[quoter]

      // If the quote hasn't changed, return the previous state
      if (quote) {
        // Only update if the new quote is different from the existing one
        if (existingQuote && JSON.stringify(existingQuote) === JSON.stringify(quote)) {
          return prevQuotes // No change, return the previous state
        }
      } else {
        // If there is no quote, but the previous state also has no quote, no need to update
        if (!existingQuote) {
          return prevQuotes
        }
      }

      // Update the state if there's a real change
      const updatedQuotes = { ...prevQuotes }

      if (quote) {
        updatedQuotes[quoter] = quote // Set the new quote
      } else {
        delete updatedQuotes[quoter] // Remove the quote if null
      }

      return updatedQuotes
    })
  }, [])

  // Automatically select the best quote based on execution price if not manually selected
  useEffect(() => {
    if (Object.keys(quotes).length > 0 && selectedQuoter === undefined) {
      const bestQuoter = Object.keys(quotes).reduce((best, current) => {
        const currentQuote = quotes[current as keyof typeof Quoters] // Cast to keyof Quoters
        const bestQuote = quotes[best as keyof typeof Quoters] // Cast to keyof Quoters
        return currentQuote && bestQuote && currentQuote.executionPrice.lessThan(bestQuote.executionPrice)
          ? (current as keyof typeof Quoters) // Cast current as Quoters
          : best
      }, Object.keys(quotes)[0] as keyof typeof Quoters) // Cast the initial value to Quoters

      const desiredQuoter = bestQuoter as Quoters
      if (selectedQuoter !== desiredQuoter) {
        // Avoid setting state if it's already set
        setSelectedQuoter(desiredQuoter)
        if (quotes[desiredQuoter]) {
          onSelectQuote(desiredQuoter, quotes[desiredQuoter] as IHasQuoteProperties)
        }
      }
    }
  }, [quotes, selectedQuoter, onSelectQuote])

  // Clear quotes and reset selection when swap parameters change
  useEffect(() => {
    setQuotes({})
    setSelectedQuoter(undefined) // Reset selection when swap parameters change
  }, [swapParams.inputCurrency, swapParams.outputCurrency, swapParams.tradeType, swapParams.amount])

  // Return early if input, output, or amount is missing
  if (!inputCurrency || !outputCurrency || !amount) {
    return null
  }

  // Handle manual quote selection by the user
  const handleSelectQuote = (quoter: Quoters, quote: IHasQuoteProperties) => {
    setSelectedQuoter(quoter)
    onSelectQuote(quoter, quote) // Pass the selected quote back to the parent
  }

  return (
    <Wrapper>
      <StyledHeaderRow disabled={Object.keys(quotes).length === 0} open={true}>
        {Object.keys(quotes).length === 0 ? (
          <StyledPolling>
            <StyledPollingDot>
              <Spinner />
            </StyledPollingDot>
            <ThemedText.DeprecatedMain fontSize={14}>Fetching best quotes...</ThemedText.DeprecatedMain>
          </StyledPolling>
        ) : (
          <RowFixed justify="between" width="100% !important">
            <ThemedText.DeprecatedMain fontSize={14}>
              {(() => {
                const selectedQuote = selectedQuoter ? quotes[selectedQuoter] : undefined
                const quoteMethod = selectedQuote?.quoteMethod

                return quoteMethod ? getQuoteMethodName(quoteMethod) : 'Choose Provider'
              })()}
            </ThemedText.DeprecatedMain>
          </RowFixed>
        )}
        <RotatingArrow stroke="#000" open={true} />
      </StyledHeaderRow>

      <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
        {Object.entries(quotes).map(([quoter, quote]) => {
          const inputAmount = quote.inputAmount
          const outputAmount = quote.outputAmount
          const executionPrice = quote.executionPrice

          // Simplified display of trade: 1 FTM For 0.47 USDC
          const tradeSummary = `1 ${inputAmount?.currency.symbol} For ${executionPrice.toSignificant(6)} ${
            outputAmount?.currency.symbol
          }`

          return (
            <li
              key={quoter}
              style={{
                padding: '8px 16px',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                backgroundColor: quoter === selectedQuoter ? theme.accent2 : 'transparent',
                borderRadius: '8px',
              }}
              onClick={() => handleSelectQuote(quoter as Quoters, quote)}
            >
              <span>{getQuoteMethodIcon(quote.quoteMethod)}</span>
              <ThemedText.DeprecatedMain fontSize={14}>
                {getQuoteMethodName(quote.quoteMethod)}
              </ThemedText.DeprecatedMain>

              <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                {/* Show simplified trade summary */}
                <ThemedText.DeprecatedSmall fontSize={12}>{`${tradeSummary}`}</ThemedText.DeprecatedSmall>
              </div>
            </li>
          )
        })}
      </ul>

      {/* Render quoters */}
      {isParaswapEnabled(swapParams) && <ParaswapQuoter swapParams={swapParams} onQuoteUpdate={handleQuoteUpdate} />}
      {isClientEnabled(swapParams) && <ClientSideQuoter swapParams={swapParams} onQuoteUpdate={handleQuoteUpdate} />}
      {isLiquidityHubEnabled(swapParams) && (
        <LiquidityHubQuoter swapParams={swapParams} onQuoteUpdate={handleQuoteUpdate} />
      )}
    </Wrapper>
  )
}
