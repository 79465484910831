import { CurrencyAmount, Percent, TradeType } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { useDebouncedTrade } from 'hooks/useDebouncedTrade' // Assuming you have a hook like this
import { useLocalCurrencyPrice } from 'hooks/useLocalCurrencyPrice'
import React, { useCallback, useEffect, useMemo } from 'react'
import { ClassicTrade, IHasQuoteProperties, TradeState } from 'state/routing/types'
import { isClassicTrade } from 'state/routing/utils'
import { computeFiatValuePriceImpact } from 'utils/computeFiatValuePriceImpact'
import { computeRealizedPriceImpact } from 'utils/prices'

import { IQuoteSwapParams, Quoters } from './QuoteManager' // Enum for quoters

interface ClientSideQuoterProps {
  swapParams: IQuoteSwapParams
  onQuoteUpdate: (quoter: Quoters, quote: IHasQuoteProperties | null) => void
}

function largerPercentValue(a?: Percent, b?: Percent) {
  if (a && b) {
    return a.greaterThan(b) ? a : b
  } else if (a) {
    return a
  } else if (b) {
    return b
  }
  return undefined
}

const computePriceImpact = (trade?: ClassicTrade, preTaxStablecoinPriceImpact?: Percent) => {
  if (!trade) return undefined
  const marketPriceImpact = trade.priceImpact ? computeRealizedPriceImpact(trade) : undefined
  return largerPercentValue(marketPriceImpact, preTaxStablecoinPriceImpact)
}

// eslint-disable-next-line import/no-unused-modules
export const ClientSideQuoter: React.FC<ClientSideQuoterProps> = ({ swapParams, onQuoteUpdate }) => {
  const { inputCurrency, outputCurrency, amount, tradeType, inputTax, outputTax } = swapParams
  const { account } = useWeb3React()

  const tradeCurrency = useMemo(
    () => (tradeType === TradeType.EXACT_INPUT ? inputCurrency : outputCurrency),
    [inputCurrency, outputCurrency, tradeType]
  )

  // Parse the amount for trade depending on tradeType
  const parsedAmount = useMemo(() => {
    if (!tradeCurrency) return undefined
    return CurrencyAmount.fromRawAmount(tradeCurrency, amount.toString())
  }, [amount, tradeCurrency])

  // Debounced trade calculation
  const tradeInfo = useDebouncedTrade(
    tradeType,
    parsedAmount,
    tradeType === TradeType.EXACT_INPUT ? outputCurrency : inputCurrency,
    undefined, // RouterPreference.CLIENT,
    account,
    inputTax,
    outputTax
  )

  const trade = tradeInfo.trade

  const fiatValueInput = useLocalCurrencyPrice(trade?.inputAmount)
  const fiatValueOutput = useLocalCurrencyPrice(trade?.outputAmount)
  const routeIsSyncing = tradeInfo.state === TradeState.LOADING && Boolean(trade)

  const preTaxStablecoinPriceImpact = useMemo(
    () =>
      routeIsSyncing || !isClassicTrade(trade)
        ? undefined
        : computeFiatValuePriceImpact(fiatValueInput.data, fiatValueOutput.data),
    [fiatValueInput, fiatValueOutput, routeIsSyncing, trade]
  )

  const resolveQuoteFromTrade = useCallback(
    (trade: ClassicTrade): IHasQuoteProperties => ({
      inputAmount: trade.inputAmount,
      outputAmount: trade.outputAmount,
      executionPrice: trade.executionPrice,
      quote: trade,
      quoteMethod: trade.quoteMethod,
      fillType: trade.fillType,
      tradeType: trade.tradeType,
      postTaxOutputAmount: trade.postTaxOutputAmount,
      approveInfo: trade.approveInfo,
      gasUseEstimateUSD: trade.gasUseEstimateUSD,
      totalGasUseEstimateUSD: trade.totalGasUseEstimateUSD,
      priceImpact: computePriceImpact(trade, preTaxStablecoinPriceImpact), // Using computePriceImpact
      fiatValueInput,
      fiatValueOutput,
      dependencies: { tradeState: tradeInfo.state, quoter: Quoters.CLIENT },
    }),
    [fiatValueInput, fiatValueOutput, preTaxStablecoinPriceImpact, tradeInfo.state]
  )

  useEffect(() => {
    if (tradeInfo.state === TradeState.VALID && tradeInfo?.trade) {
      const quote = resolveQuoteFromTrade(tradeInfo.trade)
      onQuoteUpdate(Quoters.CLIENT, quote)
    } else {
      onQuoteUpdate(Quoters.CLIENT, null)
    }
  }, [tradeInfo.state, tradeInfo.trade, resolveQuoteFromTrade, onQuoteUpdate])

  return null // This component has no UI, it's just logic
}
