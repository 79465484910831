import { Currency, CurrencyAmount, Price, TradeType } from '@uniswap/sdk-core'
import { useBestTradeLH } from 'components/swap/LiquidityHub'
import { useLocalCurrencyPrice } from 'hooks/useLocalCurrencyPrice'
import { useCallback, useEffect, useMemo } from 'react'
import { IHasQuoteProperties, QuoteMethod, TradeFillType } from 'state/routing/types'
import { computeFiatValuePriceImpact } from 'utils/computeFiatValuePriceImpact'

import { IQuoteSwapParams } from './QuoteManager'
import { Quoters } from './QuoteManager' // Enum for quoters

interface LiquidityHubQuoterProps {
  swapParams: IQuoteSwapParams
  onQuoteUpdate: (quoter: Quoters, quote: IHasQuoteProperties | null) => void
}

// eslint-disable-next-line import/no-unused-modules
export const LiquidityHubQuoter: React.FC<LiquidityHubQuoterProps> = ({
  swapParams,
  onQuoteUpdate,
}: LiquidityHubQuoterProps) => {
  const { allowedSlippage, inputCurrency, outputCurrency, amount, tradeType, inputTax, outputTax } = swapParams

  const rateInputAmount = useMemo(() => {
    return inputCurrency ? CurrencyAmount.fromRawAmount(inputCurrency, amount.toString()) : undefined
  }, [inputCurrency, amount])

  const liquidityHub = useBestTradeLH({
    allowedSlippage,
    isEnabled: tradeType === TradeType.EXACT_INPUT,
    inputCurrency,
    outputCurrency,
    inputAmount: amount.toString(),
  })

  const liquidityHubQuote = liquidityHub.useQuote()

  const rateOutputAmount = useMemo(() => {
    return outputCurrency && liquidityHubQuote.data
      ? CurrencyAmount.fromRawAmount(outputCurrency, liquidityHubQuote.data.minAmountOut ?? '0')
      : undefined
  }, [outputCurrency, liquidityHubQuote.data])

  // Ideally these should come from liquidity hub however they do not support supplying the usd price
  // So we will just return the value we have on the server
  const fiatValueInput = useLocalCurrencyPrice(rateInputAmount)
  const fiatValueOutput = useLocalCurrencyPrice(rateOutputAmount)

  const preTaxStablecoinPriceImpact = useMemo(
    () =>
      !liquidityHubQuote?.data || !rateInputAmount || !rateOutputAmount
        ? undefined
        : computeFiatValuePriceImpact(fiatValueInput.data, fiatValueOutput.data),
    [fiatValueInput.data, fiatValueOutput.data, liquidityHubQuote, rateInputAmount, rateOutputAmount]
  )

  // Function to resolve the quote from Liquidity Hub using the rates
  const resolveQuoteFromLiquidityHub = useCallback(
    (
      rateInputAmount: CurrencyAmount<Currency>,
      rateOutputAmount: CurrencyAmount<Currency>,
      quoteResponse: any
    ): IHasQuoteProperties => {
      return {
        inputAmount: rateInputAmount,
        outputAmount: rateOutputAmount,
        executionPrice: new Price(
          rateInputAmount.currency,
          rateOutputAmount.currency,
          rateInputAmount.quotient,
          rateOutputAmount.quotient
        ),
        quote: quoteResponse,
        quoteMethod: QuoteMethod.LIQUIDITY_HUB,
        fillType: TradeFillType.BestSwap,
        tradeType: TradeType.EXACT_INPUT, // Liquidity Hub only supports exact input
        postTaxOutputAmount: rateOutputAmount,
        approveInfo: { needsApprove: false },
        gasUseEstimateUSD: undefined,
        totalGasUseEstimateUSD: undefined,
        priceImpact: preTaxStablecoinPriceImpact,
        fiatValueInput,
        fiatValueOutput,
        dependencies: { liquidityHub, quoter: Quoters.LIQUIDITY_HUB },
      }
    },
    [fiatValueInput, fiatValueOutput, liquidityHub, preTaxStablecoinPriceImpact]
  )

  useEffect(() => {
    // Return early if essential values are missing; no need to invalidate the quote explicitly
    if (!liquidityHubQuote.data || !rateInputAmount || !rateOutputAmount) {
      return
    }

    // Check if Liquidity Hub supports this trade (no tax tokens and exact input)
    const isLHSwapSupport = inputTax.equalTo(0) && outputTax.equalTo(0) && tradeType === TradeType.EXACT_INPUT
    if (isLHSwapSupport) {
      const resolvedQuote = resolveQuoteFromLiquidityHub(rateInputAmount, rateOutputAmount, liquidityHubQuote.data)
      if (resolvedQuote.outputAmount.equalTo(0)) {
        onQuoteUpdate(Quoters.LIQUIDITY_HUB, null)
      } else {
        onQuoteUpdate(Quoters.LIQUIDITY_HUB, { ...resolvedQuote, isValid: true })
      }
    }
  }, [
    liquidityHubQuote.data,
    inputTax,
    outputTax,
    resolveQuoteFromLiquidityHub,
    tradeType,
    rateInputAmount,
    rateOutputAmount,
    onQuoteUpdate,
  ])

  return null // No UI needed, just logic
}
